import type StepField from 'components/core/createModify/interfaces/stepField';
import { StepFieldDisplayType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { StepFieldOptions } from 'components/core/createModify/interfaces/subStepOption';
import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import StepComponentCore from 'components/core/createModify/stepFields/StepComponentCore';
import { RetailBulkAdjustmentBuilderFields } from 'components/sections/createModify/retailBulkAdjustment/steps/interfaces';
import { getRooftopOptions } from 'components/sections/shared/ItemMetaHelpers';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { DateTimeFormat } from 'enums/dateTimeFormat';
import { FeatureBundleSet } from 'enums/featureBundle';
import { StepFieldType } from 'enums/stepFieldType';
import type {
  RetailBulkAdjustmentCreateMutationVariables,
  RetailBulkAdjustmentModifyMutationVariables,
} from 'store/api/graph/interfaces/types';
import { RetailBulkAdjustmentType } from 'store/api/graph/interfaces/types';
import { getFormattedDateTimeString, isToday } from 'utils/dateUtils';
import { isFeatureEnabledForRooftop } from 'utils/featureBundleRooftopUtils';
import {
  defineFieldValues,
  getStepField,
  getUsersRooftop,
  objectToStepFieldArray,
  setDisplayTypes,
} from 'utils/formatting/createModifyFormatUtils';
import { translate } from 'utils/intlUtils';
import { isEnumValue } from 'utils/typeUtils';

const { t } = translate;

const { PRICE, OVERLAY, DESCRIPTION, PROMOTION, DISCLAIMER } = RetailBulkAdjustmentType;

class RetailBulkAdjustmentTypeStep extends StepComponentCore {
  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props);
    const {
      tier: { activeStep, data: currentData, seededData, formData, isCreating, stepFieldData },
    } = props;

    const {
      subContexts: {
        userContext: { user, isWhiteLabelScoped, canAccessMultipleRooftops },
        featureFlags: { rooftopPackageEnabled },
      },
    } = context;

    const data = formData || currentData;
    const rooftop = seededData?.rooftopName || data.rooftopName || getUsersRooftop(user);

    const hideSchedule = !data.starting && !data.ending;

    const isPaymentOptionEnabled = isFeatureEnabledForRooftop({
      rooftop,
      feature: FeatureBundleSet.RETAIL_PAYMENT_OPTION,
      featureFlagOn: rooftopPackageEnabled,
    });

    const starting = data.starting || stepFieldData?.starting;

    const bulkAdjustmentTypeOptions: StepFieldOptions[] = [
      {
        id: RetailBulkAdjustmentType.DESCRIPTION,
        name: t('description'),
      },
      {
        id: RetailBulkAdjustmentType.DISCLAIMER,
        name: t('disclaimer'),
      },
      {
        id: RetailBulkAdjustmentType.OVERLAY,
        name: t('overlay'),
      },
      {
        id: RetailBulkAdjustmentType.PRICE,
        name: t('price'),
      },
      {
        id: RetailBulkAdjustmentType.PROMOTION,
        name: t('promotion'),
      },
    ].filter(type => {
      switch (type.id) {
        case RetailBulkAdjustmentType.PRICE: {
          return isPaymentOptionEnabled;
        }

        default: {
          return true;
        }
      }
    });

    // Converting to readable fields and setting presets
    const fields = objectToStepFieldArray(activeStep?.fields as StepFields, {
      [RetailBulkAdjustmentBuilderFields.TYPE]: {
        selectedValue: seededData?.type || data.type,
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.OMITTED, active: !isCreating },
          { type: StepFieldDisplayType.DISABLED, active: seededData?.type || !isCreating },
        ]),
        options: bulkAdjustmentTypeOptions,
      },
      [RetailBulkAdjustmentBuilderFields.ACTIVE]: {
        selectedValue: data.active ?? true,
      },
      [RetailBulkAdjustmentBuilderFields.ROOFTOP_ID]: {
        selectedValue: rooftop,
        displayType: setDisplayTypes([
          { type: StepFieldDisplayType.HIDDEN, active: !canAccessMultipleRooftops },
          { type: StepFieldDisplayType.OMITTED, active: !isCreating },
        ]),
      },
      // TODO: Move away from formatting this in the future when we support Date + Time input
      [RetailBulkAdjustmentBuilderFields.STARTING]: {
        displayType: setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: hideSchedule }),
        selectedValue: starting
          ? getFormattedDateTimeString(starting, DateTimeFormat.YEAR_MONTH_DAY_DASH_FORMAT)
          : null,
      },
      // TODO: Move away from formatting this in the future when we support Date + Time input
      [RetailBulkAdjustmentBuilderFields.ENDING]: {
        displayType: setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: hideSchedule }),
        selectedValue: data.ending
          ? getFormattedDateTimeString(data.ending, DateTimeFormat.YEAR_MONTH_DAY_DASH_FORMAT)
          : null,
      },
      [RetailBulkAdjustmentBuilderFields.SCHEDULE_ADJUSTMENT]: { selectedValue: !hideSchedule },
    });

    // Assigning pre-defined values
    this.fields = defineFieldValues(fields, data);

    // Async subpanel configurations
    this.asyncConfigurations = {
      rooftopId: {
        request: keyword => getRooftopOptions({ user, keyword, isWhiteLabelScoped }),
      },
    };
  }

  onFieldChange(stepField: StepField, e: Record<'currentTarget', { value: any }>) {
    if (stepField.queryVar === RetailBulkAdjustmentBuilderFields.SCHEDULE_ADJUSTMENT) {
      for (const field of this.fields.filter(field => field.groupType === StepFieldType.DATE)) {
        setDisplayTypes({ type: StepFieldDisplayType.HIDDEN, active: !e.currentTarget?.value }, field);
        if (!e.currentTarget?.value) {
          field.selectedValue = null;
        }
      }
    }

    super.onFieldChange(stepField, e, true);
  }

  onFieldSelection(stepField: StepField, value: any) {
    if (stepField.queryVar === RetailBulkAdjustmentBuilderFields.TYPE) {
      const bulkAdjustmentType: RetailBulkAdjustmentType | undefined = isEnumValue(RetailBulkAdjustmentType, value.id)
        ? value.id
        : undefined;
      if (bulkAdjustmentType) {
        value = value.id;
      }
    }

    super.onFieldSelection(stepField, value);
  }

  validateFields(): boolean {
    const scheduledAdjustmentToggleField = getStepField(
      RetailBulkAdjustmentBuilderFields.SCHEDULE_ADJUSTMENT,
      this.fields
    );
    const startDateField = getStepField(RetailBulkAdjustmentBuilderFields.STARTING, this.fields);
    const endDateField = getStepField(RetailBulkAdjustmentBuilderFields.ENDING, this.fields);

    /*
     * If this bulk adjustment has been set to use a scheduled time frame, ensure that there is either a start date
     * or end date given. If not display an error.
     */
    if (scheduledAdjustmentToggleField.selectedValue && !startDateField.selectedValue && !endDateField.selectedValue) {
      this.setTier({
        errors: [
          {
            message: translate.t('bulk_adjustment_schedule_date_error'),
            extensions: {
              fields: [RetailBulkAdjustmentBuilderFields.STARTING, RetailBulkAdjustmentBuilderFields.ENDING],
            },
          },
        ],
      });
      return false;
    }
    return super.validateFields();
  }

  async save() {
    const {
      tier: { data, formData, isCreating },
    } = this.props;
    const typeField = getStepField(RetailBulkAdjustmentBuilderFields.TYPE, this.fields).selectedValue;

    const variableOverrides: RetailBulkAdjustmentCreateMutationVariables | RetailBulkAdjustmentModifyMutationVariables =
      {};

    if (isCreating) {
      variableOverrides.type = typeField;
      // Clearing existing attributes
      for (const attribute of ['overlayAttributes', 'priceAttributes', 'promotionAttributes']) {
        variableOverrides[attribute] = null;
      }
      // TODO: [#2350] Remove this when the API fixes this requirement from step 1
      switch (typeField) {
        case OVERLAY: {
          variableOverrides.overlayAttributes = formData?.overlayAttributes || data?.overlayAttributes || {};
          break;
        }

        case PRICE: {
          variableOverrides.priceAttributes = formData?.priceAttributes || data?.priceAttributes || {};
          break;
        }

        case DESCRIPTION: {
          variableOverrides.descriptionAttributes =
            formData?.descriptionAttributes || data?.descriptionAttributes || {};
          break;
        }

        case PROMOTION: {
          variableOverrides.promotionAttributes = formData?.promotionAttributes || data?.promotionAttributes || {};
          break;
        }

        case DISCLAIMER: {
          variableOverrides.disclaimerAttributes = formData?.disclaimerAttributes || data?.disclaimerAttributes || {};
          break;
        }
      }
    }

    // Formatting time field

    // TODO: Move away from this when we support  Date  +  Time fields in the future
    const startingField = getStepField(RetailBulkAdjustmentBuilderFields.STARTING, this.fields);
    const endingField = getStepField(RetailBulkAdjustmentBuilderFields.ENDING, this.fields);

    variableOverrides.starting =
      !!startingField.selectedValue && !isToday(startingField.selectedValue)
        ? getFormattedDateTimeString(startingField.selectedValue)
        : null;
    variableOverrides.ending = endingField.selectedValue ? getFormattedDateTimeString(endingField.selectedValue) : null;

    // Overriding form data type with the latest always when saving
    return super.save(undefined, {
      ...variableOverrides,
    });
  }
}

export default RetailBulkAdjustmentTypeStep;
