import { StepFieldDisplayType, SubStepType } from 'components/core/createModify/interfaces/stepField';
import type { StepFields } from 'components/core/createModify/interfaces/stepFields';
import type { ListSelectionSettings } from 'components/core/createModify/stepFields/subSteps/interfaces';
import type { ListSelectionDatePickerSettings } from 'components/core/createModify/stepFields/subSteps/ListSelectionDatePicker';
import { RetailBulkAdjustmentBuilderFields } from 'components/sections/createModify/retailBulkAdjustment/steps/interfaces';
import { StepFieldSubType } from 'enums/stepFieldSubType';
import { StepFieldType } from 'enums/stepFieldType';
import { RetailBulkAdjustmentModifyParameter } from 'store/api/graph/interfaces/types';

const RetailBulkAdjustmentTypeFields: StepFields = {
  [RetailBulkAdjustmentBuilderFields.TYPE]: {
    label: 'type',
    groupType: StepFieldType.DROPDOWN,
    groupSubTypes: [StepFieldSubType.CHECKBOX],
    subStep: [SubStepType.DEFAULT],
    displayType: [StepFieldDisplayType.OMITTED],
    hasSeparator: true,
    settings: {
      hideSearchHeader: true,
    } as ListSelectionSettings,
    forceRequired: true,
  },
  [RetailBulkAdjustmentBuilderFields.ROOFTOP_ID]: {
    label: 'rooftop',
    groupType: StepFieldType.DROPDOWN,
    subStep: [SubStepType.ASYNC],
    displayType: [StepFieldDisplayType.DISABLED],
    hasSeparator: true,
  },
  [RetailBulkAdjustmentBuilderFields.NAME]: {
    label: 'name',
    clear: { field: RetailBulkAdjustmentModifyParameter._name },
  },
  [RetailBulkAdjustmentBuilderFields.ACTIVE]: {
    label: 'active',
    groupType: StepFieldType.SWITCH,
    clear: { field: RetailBulkAdjustmentModifyParameter._active },
  },
  [RetailBulkAdjustmentBuilderFields.ORDER]: {
    label: 'order',
    groupType: StepFieldType.NUMBER,
    tooltipContents: 'order_tooltip',
    clear: { field: RetailBulkAdjustmentModifyParameter._order },
  },
  [RetailBulkAdjustmentBuilderFields.SCHEDULE_ADJUSTMENT]: {
    label: 'schedule_adjustment',
    groupType: StepFieldType.SWITCH,
    displayType: [StepFieldDisplayType.OMITTED],
  },
  [RetailBulkAdjustmentBuilderFields.STARTING]: {
    label: 'start_date',
    groupType: StepFieldType.DATE,
    groupSubTypes: [StepFieldSubType.DATE],
    subStep: [SubStepType.DEFAULT],
    clear: { field: RetailBulkAdjustmentModifyParameter._starting },
    settings: {
      matrixViewConfig: true,
    } as ListSelectionDatePickerSettings,
  },
  [RetailBulkAdjustmentBuilderFields.ENDING]: {
    label: 'end_date',
    groupType: StepFieldType.DATE,
    groupSubTypes: [StepFieldSubType.DATE],
    subStep: [SubStepType.DEFAULT],
    clear: { field: RetailBulkAdjustmentModifyParameter._ending },
    settings: {
      matrixViewConfig: true,
    } as ListSelectionDatePickerSettings,
  },
};

export default RetailBulkAdjustmentTypeFields;
