import type { StepComponentProps } from 'components/core/createModify/stepFields/StepComponentCore';
import RooftopIntegration from 'components/sections/createModify/rooftopIntegration/steps/DetailsStep';
import { RooftopIntegrationDetailsFields } from 'components/sections/createModify/rooftopIntegration/steps/interfaces';
import { websiteAsyncConfiguration } from 'components/sections/createModify/rooftopIntegration/utils';
import { getOemAccountExportWebsiteBrands } from 'components/sections/shared/ItemMetaHelpers';
import type { CreateModifyContextInterface } from 'contexts/CreateModifyContext';
import { getUsersRooftop } from 'utils/formatting/createModifyFormatUtils';
import { filterByKeyword } from 'utils/metadataUtils';

class DetailsStep extends RooftopIntegration {
  constructor(props: StepComponentProps, context: CreateModifyContextInterface) {
    super(props, context);

    const {
      tier: { data, seededData },
    } = props;

    const {
      subContexts: {
        userContext: { user },
      },
    } = context;

    const rooftop = seededData?.rooftopName || data.rooftopName || getUsersRooftop(user);

    for (const field of this.fields) {
      switch (field.queryVar) {
        case RooftopIntegrationDetailsFields.OEM_ACCOUNT_EXPORT_WEBSITE: {
          field.selectedValue = data?.oemAccountExportAttributes?.website;
          break;
        }

        case RooftopIntegrationDetailsFields.ADVERTISING_BUDGET: {
          field.selectedValue = data?.oemAccountExportAttributes?.advertisingBudget?.amount;
          break;
        }
      }
    }

    this.asyncConfigurations = {
      ...this.asyncConfigurations,
      [RooftopIntegrationDetailsFields.OEM_ACCOUNT_EXPORT_WEBSITE]: websiteAsyncConfiguration(rooftop.group.id),
      [RooftopIntegrationDetailsFields.OEM_ACCOUNT_EXPORT_WEBSITE_BRAND]: {
        request: async (keyword: string) => {
          const websiteBrands = await getOemAccountExportWebsiteBrands();
          return filterByKeyword(keyword, websiteBrands || []);
        },
      },
    };
  }
}

export default DetailsStep;
