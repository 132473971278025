import { RooftopIntegrationAttributesPointer } from 'enums/rooftopIntegrationAttributesPointer';
import { translateFields } from 'utils/intlUtils';
import type { TableSettingsType } from 'utils/tableUtils';

import { FieldDataType } from '../fieldDataType';

export enum RooftopIntegration {
  ACTIVE = 'active',
  PRIMARY = 'primary',
  INTEGRATION = 'integration',
  INTEGRATION_NAME = 'integrationName',
  INTEGRATION_TYPE = 'integrationType',
  THIRD_PARTY_ROOFTOP_ID = 'thirdPartyRooftopId',
  CPP_WEBSITE = `${RooftopIntegrationAttributesPointer.CPP}.website.name`,
  WEBSITE_ROUTES = `${RooftopIntegrationAttributesPointer.CPP}.websiteRoutes`,
  WEBSITE_PAGE_TYPE = `${RooftopIntegrationAttributesPointer.CPP}.websitePageTypeName`,
  OEM_ACCOUNT_EXPORT_WEBSITE = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.website.name`,
  OEM_ACCOUNT_EXPORT_WEBSITE_BRAND = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.websiteBrand`,
  DEALER_CODE = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.dealerCode`,
  BAC = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.bac`,
  ADVERTISING_BUDGET = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.advertisingBudget`,
  ROOFTOP_NAME_OVERRIDE = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.rooftopNameOverride`,
  ABOUT_US_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.aboutUsUrl`,
  ACCESSORIES_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.accessoriesUrl`,
  ALL_INVENTORY_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.allInventoryUrl`,
  CONTACT_US_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.contactUsUrl`,
  CPO_INVENTORY_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.cpoInventoryUrl`,
  MAP_DIRECTIONS_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.mapDirectionsUrl`,
  NEW_INVENTORY_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.newInventoryUrl`,
  PARTS_REQUEST_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.partsRequestUrl`,
  PERFORMANCE_PARTS_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.performancePartsUrl`,
  SERVICE_APPOINTMENT_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.serviceAppointmentUrl`,
  SERVICE_MENU_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.serviceMenuUrl`,
  SERVICE_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.serviceUrl`,
  SPECIALS_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.specialsUrl`,
  TIRE_FINDER_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.tireFinderUrl`,
  TIRE_REQUEST_QUOTE_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.tireRequestQuoteUrl`,
  USED_INVENTORY_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.usedInventoryUrl`,
  LEAD_DISTRIBUTION_WEBSITE = `${RooftopIntegrationAttributesPointer.LEAD_DISTRIBUTION}.website.name`,
  LEAD_DISTRIBUTION_THIRD_PARTY_ID = `${RooftopIntegrationAttributesPointer.LEAD_DISTRIBUTION}.thirdPartyId`,
  INVENTORY_EXPORT_THIRD_PARTY_ID = `${RooftopIntegrationAttributesPointer.INVENTORY_EXPORT}.thirdPartyId`,
  INVENTORY_EXPORT_WEBSITE_BRAND = `${RooftopIntegrationAttributesPointer.INVENTORY_EXPORT}.websiteBrand`,
  PHONE_CALL_TRACKING_PHONE_NUMBERS = `${RooftopIntegrationAttributesPointer.PHONE_CALL_TRACKING}.phoneNumbers`,
  IMPORT_PHOTOS = 'importPhotos',
  RUN_ONCE = 'runOnce',
  ROOFTOP_NAME = 'rooftopName.name',
  PRIVACY_POLICY_URL = 'privacyPolicyUrl',
  CREATED = 'created',
  UPDATED = 'updated',
}

const RooftopIntegrationConfig: TableSettingsType = {
  [RooftopIntegration.PRIVACY_POLICY_URL]: { label: 'privacy_policy', type: FieldDataType.DEFAULT },
  [RooftopIntegration.INTEGRATION_NAME]: { label: 'integration_one', type: FieldDataType.NAME },
  [RooftopIntegration.INTEGRATION_TYPE]: { label: 'type', type: FieldDataType.NAME },
  [RooftopIntegration.ACTIVE]: { label: 'active', type: FieldDataType.BOOLEAN },
  [RooftopIntegration.PRIMARY]: { label: 'primary', type: FieldDataType.BOOLEAN },
  [RooftopIntegration.IMPORT_PHOTOS]: { label: 'import_photos', type: FieldDataType.BOOLEAN },
  [RooftopIntegration.RUN_ONCE]: { label: 'run_once', type: FieldDataType.BOOLEAN, tooltip: 'run_once_tooltip' },
  [RooftopIntegration.THIRD_PARTY_ROOFTOP_ID]: { label: 'third_party_id', type: FieldDataType.DEFAULT },
  [RooftopIntegration.WEBSITE_PAGE_TYPE]: { label: 'website_page_type', type: FieldDataType.DEFAULT },
  [RooftopIntegration.CPP_WEBSITE]: { label: 'website_one', type: FieldDataType.DEFAULT },
  [RooftopIntegration.WEBSITE_ROUTES]: { label: 'website_route_other', type: FieldDataType.BADGE_LIST },
  [RooftopIntegration.OEM_ACCOUNT_EXPORT_WEBSITE]: { label: 'website_one', type: FieldDataType.DEFAULT },
  [RooftopIntegration.LEAD_DISTRIBUTION_WEBSITE]: { label: 'website_one', type: FieldDataType.DEFAULT },
  [RooftopIntegration.LEAD_DISTRIBUTION_THIRD_PARTY_ID]: { label: 'third_party_id', type: FieldDataType.DEFAULT },
  [RooftopIntegration.INVENTORY_EXPORT_THIRD_PARTY_ID]: { label: 'third_party_id', type: FieldDataType.DEFAULT },
  [RooftopIntegration.OEM_ACCOUNT_EXPORT_WEBSITE_BRAND]: { label: 'website_brand', type: FieldDataType.ENUM },
  [RooftopIntegration.INVENTORY_EXPORT_WEBSITE_BRAND]: {
    label: 'website_brand',
    type: FieldDataType.ENUM,
    tooltip: 'inventory_export_website_brand_tooltip',
  },
  [RooftopIntegration.DEALER_CODE]: { label: 'dealer_code', type: FieldDataType.DEFAULT },
  [RooftopIntegration.BAC]: { label: 'bac', type: FieldDataType.DEFAULT },
  [RooftopIntegration.ADVERTISING_BUDGET]: { label: 'advertising_budget', type: FieldDataType.MONETARY },
  [RooftopIntegration.ROOFTOP_NAME_OVERRIDE]: { label: 'rooftop_name_override', type: FieldDataType.DEFAULT },
  [RooftopIntegration.ABOUT_US_URL]: { label: 'about_us', type: FieldDataType.DEFAULT },
  [RooftopIntegration.ACCESSORIES_URL]: { label: 'accessories', type: FieldDataType.DEFAULT },
  [RooftopIntegration.ALL_INVENTORY_URL]: { label: 'all_inventory', type: FieldDataType.DEFAULT },
  [RooftopIntegration.CONTACT_US_URL]: { label: 'contact_us', type: FieldDataType.DEFAULT },
  [RooftopIntegration.CPO_INVENTORY_URL]: { label: 'cpo_inventory', type: FieldDataType.DEFAULT },
  [RooftopIntegration.MAP_DIRECTIONS_URL]: { label: 'map_directions', type: FieldDataType.DEFAULT },
  [RooftopIntegration.NEW_INVENTORY_URL]: { label: 'new_inventory', type: FieldDataType.DEFAULT },
  [RooftopIntegration.PARTS_REQUEST_URL]: { label: 'parts_request', type: FieldDataType.DEFAULT },
  [RooftopIntegration.PERFORMANCE_PARTS_URL]: { label: 'performance_parts', type: FieldDataType.DEFAULT },
  [RooftopIntegration.SERVICE_APPOINTMENT_URL]: { label: 'service_appointment', type: FieldDataType.DEFAULT },
  [RooftopIntegration.SERVICE_MENU_URL]: { label: 'service_menu', type: FieldDataType.DEFAULT },
  [RooftopIntegration.SERVICE_URL]: { label: 'service', type: FieldDataType.DEFAULT },
  [RooftopIntegration.SPECIALS_URL]: { label: 'specials', type: FieldDataType.DEFAULT },
  [RooftopIntegration.TIRE_FINDER_URL]: { label: 'tire_finder', type: FieldDataType.DEFAULT },
  [RooftopIntegration.TIRE_REQUEST_QUOTE_URL]: { label: 'tire_request_a_quote', type: FieldDataType.DEFAULT },
  [RooftopIntegration.USED_INVENTORY_URL]: { label: 'used_inventory', type: FieldDataType.DEFAULT },
  [RooftopIntegration.CREATED]: { label: 'created', type: FieldDataType.DAY_TIME },
  [RooftopIntegration.UPDATED]: { label: 'updated', type: FieldDataType.DAY_TIME },
  [RooftopIntegration.ROOFTOP_NAME]: { label: 'rooftop', type: FieldDataType.DEFAULT },
  [RooftopIntegration.PHONE_CALL_TRACKING_PHONE_NUMBERS]: { label: 'phone_number_other', type: FieldDataType.CUSTOM },
};

export const RooftopIntegrationSettings = translateFields(RooftopIntegrationConfig);
