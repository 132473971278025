import { RooftopIntegrationAttributesPointer } from 'enums/rooftopIntegrationAttributesPointer';
import type { IntegrationType } from 'store/api/graph/interfaces/types';

export enum RooftopIntegrationDetailsFields {
  ROOFTOP_ID = 'rooftopId',
  INTEGRATION_TYPE = 'integrationType',
  INTEGRATION_ID = 'integrationId',
  THIRD_PARTY_ROOFTOP_ID = 'thirdPartyRooftopId',
  ACTIVE = 'active',
  PRIMARY = 'primary',
  IMPORT_PHOTOS = 'importPhotos',
  RUN_ONCE = 'runOnce',
  PRIVACY_POLICY_URL = 'privacyPolicyUrl',
  // CPP Attribute Fields
  CPP_WEBSITE = `${RooftopIntegrationAttributesPointer.CPP}.websiteId`,
  WEBSITE_ROUTES = `${RooftopIntegrationAttributesPointer.CPP}.websiteRouteIds`,
  WEBSITE_PAGE_TYPE = `${RooftopIntegrationAttributesPointer.CPP}.websitePageType`,
  // OEM_ACCOUNT_EXPORT Attribute Fields
  OEM_ACCOUNT_EXPORT_WEBSITE = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.websiteId`,
  OEM_ACCOUNT_EXPORT_WEBSITE_BRAND = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.websiteBrand`,
  BAC = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.bac`,
  ADVERTISING_BUDGET = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.advertisingBudget`,
  DEALER_CODE = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.dealerCode`,
  ROOFTOP_NAME_OVERRIDE = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.rooftopNameOverride`,
  ABOUT_US_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.aboutUsUrl`,
  ACCESSORIES_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.accessoriesUrl`,
  ALL_INVENTORY_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.allInventoryUrl`,
  CONTACT_US_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.contactUsUrl`,
  CPO_INVENTORY_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.cpoInventoryUrl`,
  MAP_DIRECTIONS_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.mapDirectionsUrl`,
  NEW_INVENTORY_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.newInventoryUrl`,
  PARTS_REQUEST_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.partsRequestUrl`,
  PERFORMANCE_PARTS_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.performancePartsUrl`,
  SERVICE_APPOINTMENT_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.serviceAppointmentUrl`,
  SERVICE_MENU_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.serviceMenuUrl`,
  SERVICE_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.serviceUrl`,
  SPECIALS_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.specialsUrl`,
  TIRE_FINDER_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.tireFinderUrl`,
  TIRE_REQUEST_QUOTE_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.tireRequestQuoteUrl`,
  USED_INVENTORY_URL = `${RooftopIntegrationAttributesPointer.OEM_ACCOUNT_EXPORT}.usedInventoryUrl`,
  // LEAD_DISTRIBUTION Attribute Fields
  LEAD_DISTRIBUTION_WEBSITE = `${RooftopIntegrationAttributesPointer.LEAD_DISTRIBUTION}.websiteId`,
  LEAD_DISTRIBUTION_THIRD_PARTY_ID = `${RooftopIntegrationAttributesPointer.LEAD_DISTRIBUTION}.thirdPartyId`,
  // INVENTORY_EXPORT Attribute Fields
  INVENTORY_EXPORT_THIRD_PARTY_ID = `${RooftopIntegrationAttributesPointer.INVENTORY_EXPORT}.thirdPartyId`,
  INVENTORY_EXPORT_WEBSITE_BRAND = `${RooftopIntegrationAttributesPointer.INVENTORY_EXPORT}.websiteBrand`,
  // PHONE_CALL_TRACKING Attribute Fields
  PHONE_CALL_TRACKING_DESKTOP_SALES_NUMBER = `${RooftopIntegrationAttributesPointer.PHONE_CALL_TRACKING}.desktopSalesNumber`,
  PHONE_CALL_TRACKING_DESKTOP_SERVICE_NUMBER = `${RooftopIntegrationAttributesPointer.PHONE_CALL_TRACKING}.desktopServiceNumber`,
  PHONE_CALL_TRACKING_DESKTOP_PARTS_NUMBER = `${RooftopIntegrationAttributesPointer.PHONE_CALL_TRACKING}.desktopPartsNumber`,
  PHONE_CALL_TRACKING_MOBILE_SALES_NUMBER = `${RooftopIntegrationAttributesPointer.PHONE_CALL_TRACKING}.mobileSalesNumber`,
  PHONE_CALL_TRACKING_MOBILE_SERVICE_NUMBER = `${RooftopIntegrationAttributesPointer.PHONE_CALL_TRACKING}.mobileServiceNumber`,
  PHONE_CALL_TRACKING_MOBILE_PARTS_NUMBER = `${RooftopIntegrationAttributesPointer.PHONE_CALL_TRACKING}.mobilePartsNumber`,
}

export type IntegrationTypeOptions = {
  id: IntegrationType;
  name: string;
};

export enum RooftopIntegrationBuilderStep {
  DETAILS = 'ROOFTOP_INTEGRATION_DETAILS',
  URLS = 'ROOFTOP_INTEGRATION_URLS',
  FILTERS = 'ROOFTOP_INTEGRATION_FILTERS',
}
