import { RooftopIntegrationDetailsFields } from 'components/sections/createModify/rooftopIntegration/steps/interfaces';
import { BuilderType } from 'enums/builderType';
import { RooftopIntegration } from 'enums/columns/rooftopIntegration';
import { IntegrationType } from 'store/api/graph/interfaces/types';

/**
 * Helper util that will determine if a given integration field is enabled, depending on the type of integration. Will
 * return true if the field is enabled, false if it should be hidden. This util is used in both the rooftop integration
 * builder and details view.
 *
 * @param field - the integration field
 * @param integration - the type of integration
 */
export const isIntegrationFieldEnabled = (
  field: RooftopIntegrationDetailsFields | RooftopIntegration,
  integration: IntegrationType | undefined,
  featureFlagOn = false
): boolean => {
  // If integration is null/undefined, then technically none of these fields should be enabled
  if (!integration) {
    return false;
  }

  switch (field) {
    case RooftopIntegrationDetailsFields.PRIMARY:
    case RooftopIntegration.PRIMARY: {
      return integration === IntegrationType.INVENTORY_IMPORT;
    }

    case RooftopIntegrationDetailsFields.IMPORT_PHOTOS:
    case RooftopIntegration.IMPORT_PHOTOS: {
      return [IntegrationType.PHOTO_IMPORT, IntegrationType.INVENTORY_IMPORT].includes(integration);
    }

    case RooftopIntegrationDetailsFields.RUN_ONCE:
    case RooftopIntegration.RUN_ONCE: {
      return ![
        IntegrationType.CHAT_PROVIDER,
        IntegrationType.CPP,
        IntegrationType.OEM_ACCOUNT_EXPORT,
        IntegrationType.LEAD_DISTRIBUTION,
        IntegrationType.PHONE_CALL_TRACKING,
      ].includes(integration);
    }

    case RooftopIntegrationDetailsFields.CPP_WEBSITE:
    case RooftopIntegrationDetailsFields.WEBSITE_ROUTES:
    case RooftopIntegrationDetailsFields.WEBSITE_PAGE_TYPE:
    case RooftopIntegration.CPP_WEBSITE:
    case RooftopIntegration.WEBSITE_ROUTES:
    case RooftopIntegration.WEBSITE_PAGE_TYPE: {
      return integration === IntegrationType.CPP;
    }

    case RooftopIntegrationDetailsFields.PRIVACY_POLICY_URL:
    case RooftopIntegration.PRIVACY_POLICY_URL:
    case RooftopIntegrationDetailsFields.INVENTORY_EXPORT_THIRD_PARTY_ID:
    case RooftopIntegration.INVENTORY_EXPORT_THIRD_PARTY_ID:
    case RooftopIntegrationDetailsFields.INVENTORY_EXPORT_WEBSITE_BRAND:
    case RooftopIntegration.INVENTORY_EXPORT_WEBSITE_BRAND: {
      return integration === IntegrationType.INVENTORY_EXPORT;
    }

    case RooftopIntegrationDetailsFields.OEM_ACCOUNT_EXPORT_WEBSITE:
    case RooftopIntegration.OEM_ACCOUNT_EXPORT_WEBSITE:
    case RooftopIntegrationDetailsFields.OEM_ACCOUNT_EXPORT_WEBSITE_BRAND:
    case RooftopIntegration.OEM_ACCOUNT_EXPORT_WEBSITE_BRAND:
    case RooftopIntegrationDetailsFields.DEALER_CODE:
    case RooftopIntegration.DEALER_CODE:
    case RooftopIntegrationDetailsFields.BAC:
    case RooftopIntegration.BAC:
    case RooftopIntegrationDetailsFields.ADVERTISING_BUDGET:
    case RooftopIntegration.ADVERTISING_BUDGET:
    case RooftopIntegrationDetailsFields.ROOFTOP_NAME_OVERRIDE:
    case RooftopIntegration.ROOFTOP_NAME_OVERRIDE: {
      return integration === IntegrationType.OEM_ACCOUNT_EXPORT && featureFlagOn;
    }

    case RooftopIntegrationDetailsFields.LEAD_DISTRIBUTION_WEBSITE:
    case RooftopIntegrationDetailsFields.LEAD_DISTRIBUTION_THIRD_PARTY_ID:
    case RooftopIntegration.LEAD_DISTRIBUTION_WEBSITE:
    case RooftopIntegration.LEAD_DISTRIBUTION_THIRD_PARTY_ID: {
      return integration === IntegrationType.LEAD_DISTRIBUTION;
    }

    case RooftopIntegrationDetailsFields.THIRD_PARTY_ROOFTOP_ID:
    case RooftopIntegration.THIRD_PARTY_ROOFTOP_ID: {
      return ![
        IntegrationType.OEM_ACCOUNT_EXPORT,
        IntegrationType.LEAD_DISTRIBUTION,
        IntegrationType.INVENTORY_EXPORT,
        IntegrationType.PHONE_CALL_TRACKING,
      ].includes(integration);
    }

    case RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_DESKTOP_SALES_NUMBER:
    case RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_DESKTOP_SERVICE_NUMBER:
    case RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_DESKTOP_PARTS_NUMBER:
    case RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_MOBILE_SALES_NUMBER:
    case RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_MOBILE_SERVICE_NUMBER:
    case RooftopIntegrationDetailsFields.PHONE_CALL_TRACKING_MOBILE_PARTS_NUMBER:
    case RooftopIntegration.PHONE_CALL_TRACKING_PHONE_NUMBERS: {
      return integration === IntegrationType.PHONE_CALL_TRACKING;
    }

    default: {
      return true;
    }
  }
};

export const getEditBuilderForIntegrationType = (type: IntegrationType): BuilderType => {
  switch (type) {
    case IntegrationType.INVENTORY_EXPORT: {
      return BuilderType.ROOFTOP_INTEGRATION_INVENTORY_EXPORT_MODIFY;
    }

    case IntegrationType.OEM_ACCOUNT_EXPORT: {
      return BuilderType.ROOFTOP_INTEGRATION_OEM_ACCOUNT_EXPORT_MODIFY;
    }

    case IntegrationType.PHONE_CALL_TRACKING: {
      return BuilderType.ROOFTOP_INTEGRATION_PHONE_CALL_TRACKING_MODIFY;
    }

    default: {
      return BuilderType.ROOFTOP_INTEGRATION_MODIFY;
    }
  }
};
