import { gql } from '@apollo/client';

import { monetaryAmount } from 'store/api/graph/fragments/monetaryAmount';

import { integrationWebsite } from './integrationWebsite';

export const oemAccountExportRooftopIntegrationAttributes = gql`
  fragment OemAccountExportRooftopIntegrationAttributesFragment on OemAccountExportRooftopIntegrationAttributes {
    aboutUsUrl
    accessoriesUrl
    advertisingBudget {
      ...MonetaryAmountFragment
    }
    allInventoryUrl
    bac
    contactUsUrl
    cpoInventoryUrl
    dealerCode
    mapDirectionsUrl
    newInventoryUrl
    partsRequestUrl
    performancePartsUrl
    rooftopNameOverride
    serviceAppointmentUrl
    serviceMenuUrl
    serviceUrl
    specialsUrl
    tireFinderUrl
    tireRequestQuoteUrl
    usedInventoryUrl
    website {
      ...IntegrationWebsiteFragment
    }
    websiteBrand
  }

  ${monetaryAmount}
  ${integrationWebsite}
`;
