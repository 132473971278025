import type { ComponentProps, ComponentType, HTMLProps } from 'react';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import { useMergeRefs } from '@floating-ui/react';
import { throttle } from 'lodash-es';

import type { ArrowPosition, TooltipMargin } from 'components/ui/shared/Tooltip';
import Tooltip, { PrimaryArrowPosition, SecondaryArrowPosition } from 'components/ui/shared/Tooltip';

export interface TooltipProps extends Omit<ComponentProps<typeof Tooltip>, 'anchor'> {
  /** When true, it will automatically display the tooltip on hover */
  shouldDisplayOnHover?: boolean;
  /** The expected width of the tooltip, used as a cutoff point for repositioning it based on browser width */
  width: number;
  /**
   * Whether or not to wrap the component in a <div>.
   * Used for when said component cannot take in a `ref` and cannot be wrapped in a `forwardRef`,
   * like our icon components.
   */
  wrapComponent?: boolean;
}

interface WithTooltipProps {
  tooltip: TooltipProps;
}

/**
 * An HOC that adds a tooltip to the given component, setting that component as the tooltip's anchor.
 * Also checks for the tooltip's width relative to the window's bounds to ensure that it is never
 * horizontally cut off by the window.
 *
 * TODO: Potentially add hover and click state management built in e.g. withHoverTooltip
 */
export function withTooltip<TProps extends object>(Component: ComponentType<TProps>) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = Component.displayName || Component.name || 'Component';

  const ComponentWithTooltip = forwardRef<HTMLElement, HTMLProps<HTMLElement> & TProps & WithTooltipProps>(
    (
      {
        tooltip: {
          shouldShow,
          shouldDisplayOnHover = false,
          width: tooltipWidth,
          arrowPosition: defaultArrowPosition,
          margin: defaultMargin,
          wrapComponent,
          ...tooltipProps
        },
        ...componentProps
      },
      ref
    ) => {
      const tooltipAnchor = useRef<Element>(null);
      const [tooltipArrowPosition, setTooltipArrowPosition] = useState<ArrowPosition>();
      const [tooltipMargin, setTooltipMargin] = useState<TooltipMargin>();
      const [shouldShowTooltip, setShouldShowTooltip] = useState(shouldShow);

      const onMouseEnter = shouldDisplayOnHover
        ? throttle(() => {
            setShouldShowTooltip(true);
          }, 100)
        : undefined;

      const onMouseLeave = shouldDisplayOnHover
        ? throttle(() => {
            setShouldShowTooltip(false);
          }, 100)
        : undefined;

      const onWindowResized = useCallback(() => {
        const rect = tooltipAnchor.current?.getBoundingClientRect();
        if (!rect) {
          return;
        }
        if (
          (defaultArrowPosition?.primary === undefined ||
            defaultArrowPosition?.primary === PrimaryArrowPosition.LEFT) &&
          window.innerWidth < rect.right + tooltipWidth
        ) {
          setTooltipArrowPosition({ primary: PrimaryArrowPosition.BOTTOM, secondary: SecondaryArrowPosition.RIGHT });
          setTooltipMargin({ x: 0, y: 10 });
        } else if (defaultArrowPosition?.primary === PrimaryArrowPosition.RIGHT && rect.left - tooltipWidth < 10) {
          setTooltipArrowPosition({ primary: PrimaryArrowPosition.BOTTOM, secondary: SecondaryArrowPosition.LEFT });
          setTooltipMargin({ x: 0, y: 10 });
        } else {
          setTooltipArrowPosition(defaultArrowPosition);
          setTooltipMargin(defaultMargin);
        }
      }, [defaultArrowPosition, defaultMargin, tooltipWidth]);

      useEffect(() => {
        window.addEventListener('resize', onWindowResized);
        onWindowResized();
        return () => window.removeEventListener('resize', onWindowResized);
      }, [onWindowResized]);

      const refs = useMergeRefs([ref, tooltipAnchor]);

      return (
        <>
          {wrapComponent ? (
            <div ref={refs} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
              {/**
               * Assert the passed props as TProps due to the TS 3.2 bug
               * @see https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
               */}
              <Component {...(componentProps as TProps)} />
            </div>
          ) : (
            <Component {...(componentProps as TProps)} ref={refs} />
          )}
          <Tooltip
            {...tooltipProps}
            anchor={tooltipAnchor}
            arrowPosition={tooltipArrowPosition}
            margin={tooltipMargin}
            shouldShow={shouldDisplayOnHover ? shouldShowTooltip : shouldShow}
          />
        </>
      );
    }
  );

  ComponentWithTooltip.displayName = `withTooltip(${displayName})`;

  return ComponentWithTooltip;
}
