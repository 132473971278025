import { useCallback, useState } from 'react';

import styled, { css } from 'styled-components/macro';

import LockIcon from 'components/ui/icons/LockIcon';
import UnlockIcon from 'components/ui/icons/UnlockIcon';
import DefaultPopoverLayout from 'components/ui/layouts/PopoverLayout';
import Popover from 'components/ui/popover/Popover';
import PopoverContent from 'components/ui/popover/PopoverContent';
import PopoverTrigger from 'components/ui/popover/PopoverTrigger';
import { PrimaryArrowPosition, SecondaryArrowPosition } from 'components/ui/shared/Tooltip';
import { ElementTestId } from 'enums/testing';
import { withTooltip } from 'hooks/withTooltip';
import { BODY_TEXT_SECONDARY, BODY_TEXT_TERTIARY, DIVIDER } from 'styles/color';
import { NEUTRAL_0, NEUTRAL_800 } from 'styles/tokens';
import { translate } from 'utils/intlUtils';

const { t } = translate;
const LockIconButton = styled.button<{ isActive: boolean }>`
  padding: 4px 6px;
  box-shadow: inset 0 0 0 1px ${BODY_TEXT_TERTIARY};
  border-radius: 4px;
  background: ${NEUTRAL_0};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${NEUTRAL_800};

  &:hover {
    background: ${DIVIDER};
    box-shadow: inset 0 0 0 1px ${BODY_TEXT_SECONDARY};
  }

  :active {
    color: ${NEUTRAL_0};
    background: ${NEUTRAL_800};
    box-shadow: none;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${NEUTRAL_0};
      background: ${NEUTRAL_800};
      box-shadow: none;

      &:hover {
        color: ${NEUTRAL_0};
        background: ${NEUTRAL_800};
        box-shadow: none;
      }
    `}
`;

const Container = styled.div`
  display: flex;
  margin-right: 8px;
`;

const LockButtonWithTooltip = withTooltip(LockIconButton);

type LockButtonPopoverToggleProps = {
  /** The callback to run when the lock/unlock button is clicked */
  onConfirm?: () => void;
  /** The id for testing (typically a queryVar) */
  testId: string;
  /** Whether this field is currently locked */
  isLocked?: boolean;
  /** Whether the LockButton Popover should be open initially */
  initialOpen?: boolean;
};

/**
 * A component that renders a toggle button with a lock/unlock icon.
 * Opens and closes a controlled Popover with a confirmation button that will run the provided
 * onConfirm callback.
 */
const LockButton = ({ onConfirm, testId, isLocked = false, initialOpen = false }: LockButtonPopoverToggleProps) => {
  const [isOpen, setIsOpen] = useState(initialOpen);

  const handleDialogConfirm = useCallback(() => {
    onConfirm?.();
    setIsOpen(false);
  }, [onConfirm]);

  return (
    <Popover offset={4} placement="bottom-start" isOpen={isOpen} onOpenChange={setIsOpen}>
      <Container>
        <PopoverTrigger asChild onClick={() => setIsOpen(!isOpen)}>
          <LockButtonWithTooltip
            data-testid={`${ElementTestId.LOCK_BUTTON_TOGGLE}-${testId}`}
            tooltip={{
              shouldShow: false,
              shouldDisplayOnHover: true,
              isAnimated: true,
              children: t('manage_lock_status'),
              width: 100,
              wrapComponent: true,
              arrowPosition: {
                primary: PrimaryArrowPosition.BOTTOM,
                secondary: SecondaryArrowPosition.LEFT,
              },
              margin: { x: 0, y: -2 },
            }}
            isActive={isOpen}
            as="div" // TODO: [ED-10792] Prevents potential DOM nesting warning, since parent element is sometimes HTMLButtonElement
          >
            {isLocked ? <LockIcon /> : <UnlockIcon />}
          </LockButtonWithTooltip>
        </PopoverTrigger>
        <PopoverContent as="" style={{ zIndex: 9999 }}>
          <DefaultPopoverLayout
            title={t('lock_status')}
            confirmText={isLocked ? t('unlock_field') : t('lock_field')}
            onConfirm={handleDialogConfirm}
            testId={`${ElementTestId.LOCK_POPOVER_CONTENT_CONTAINER}-${testId}`}
          >
            {isLocked ? t('field_unlock_message') : t('field_lock_message')}
          </DefaultPopoverLayout>
        </PopoverContent>
      </Container>
    </Popover>
  );
};

export default LockButton;
